<template>
  <div class="search__bg" @click="close">
    <div class="search__container">
      <input
        type="text"
        v-model="input"
        placeholder="Cerca una storia o un nome"
        @input="searcing"
      />
      <img
        src="../assets/valo-arrow.svg"
        alt=""
        @click="$store.commit('toggleSearch')"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: "",
    };
  },
  methods: {
    close(e) {
      if (e.target.className === "search__bg") {
        this.$store.commit("toggleSearch");
      }
    },
    searcing(e) {
      this.$store.commit("updateSearch", e.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  &__bg {
    position: fixed;
    overflow-y: scroll;
    overscroll-behavior: contain;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    top: 0;
    left: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999;
    // background-color: rgba(255, 255, 255, 0.7);
    // @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    //   -webkit-backdrop-filter: blur(2px);
    //   backdrop-filter: blur(2px);
    //   background-color: rgba(255, 255, 255, 0.3);
    // }
    animation: enterBg 300ms ease-out forwards;
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      animation: enterBgBl 300ms ease-out forwards;
    }
  }
  &__container {
    position: relative;
    // margin-top: 22vh;
    background: $main-color;
    padding: 52px 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    gap: 20px;

    width: 100vw;
    height: 250px;
    animation: enterSearch 300ms ease-out;
    @media screen and (max-width: 767px) {
      padding: 32px 20px;
    }
    // border-radius: 70px;

    input {
      // width: 800px;
      width: calc(90vw - 60px);
      max-width: calc(1120px - 60px);
      background: none;
      border: none;
      outline: none;
      color: $main-dark-color;
      font-family: Roboto, sans-serif;
      font-size: 28px;
      border-bottom: 2px dotted $main-dark-color;
      @media screen and (max-width: 767px) {
        font-size: 18px;
        width: 100%;
      }
      &::placeholder {
        color: $main-dark-color;
        opacity: 0.7;
      }
    }

    img {
      height: 40px;
      width: auto;
      cursor: pointer;
      @media screen and (max-width: 767px) {
        height: 20px;
      }
      filter: brightness(0.5);
      &:hover {
        filter: brightness(1.5);
      }
    }
  }
  @keyframes enterSearch {
    from {
      transform: translateY(-20vh);
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
  @keyframes enterBgBl {
    from {
      -webkit-backdrop-filter: blur(0px);
      backdrop-filter: blur(0px);
      background-color: rgba(255, 255, 255, 0);
    }

    to {
      -webkit-backdrop-filter: blur(2px);
      backdrop-filter: blur(2px);
      background-color: rgba(255, 255, 255, 0.3);
    }
  }
  @keyframes enterBg {
    from {
      background-color: rgba(255, 255, 255, 0);
    }

    to {
      background-color: rgba(255, 255, 255, 0.7);
    }
  }
}
</style>
