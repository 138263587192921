<template>
  <div
    class="listEl__container"
    :class="hover === true ? 'hovering' : ''"
    @mouseenter="showImg"
    @mouseleave="removeImg"
    @click="trigPopup(story.data)"
  >
    <div class="listEl__border">
      <div class="listEl__story">{{ story.data.titolo }}</div>
      <div class="listEl__name">{{ story.data.nome }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    // name: String,
    // story: String,
    // img: String,
    hover: Boolean,
    story: Object,
  },
  data() {
    return {
      popup: false,
    };
  },
  methods: {
    trigPopup(data) {
      this.$store.commit("openPopup", data);
    },
    showImg(event) {
      if (window.innerWidth > 767) {
        // console.log(event.target)
        // var elem = document.createElement("img");
        // elem.setAttribute("src", this.story.data.cartolina.url);
        // elem.classList.add("cartolina");
        var elem = document.createElement("div");
        elem.style.backgroundImage = `url(${this.story.data.cartolina.url})`;
        elem.classList.add("cartolina");
        event.target.append(elem);
      }
    },
    removeImg(event) {
      if (event.target.lastChild.className === "cartolina") {
        var cart = document.querySelector(".cartolina");
        cart.parentElement.removeChild(cart);
        // console.log(cart.parentElement)
      }
      // if(event.target.lastCHild)
    },
  },
};
</script>

<style lang="scss">
.hovering {
  transition: ease-out 300ms;
  opacity: 0.2;
  &:hover {
    opacity: 1;
  }
}
.listEl {
  &__container {
    width: 100%;
    position: relative;
    cursor: pointer;

    @media not all and (hover: none) {
      &:hover {
        // background: red;
        .listEl__border {
          border: 2px dotted $main-dark-color;
          width: 95%;
          border-radius: 100px;
          padding: 0 30px;
        }
      }
    }
  }
  &__border {
    display: flex;
    margin: 8px auto;
    justify-content: space-between;
    align-items: center;
    border: 2px dotted #00000000;
    transition: ease-out 300ms;
    border-radius: 20px;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      margin: 22px auto;
    }
    // &:hover {
    //   border: 2px dotted black;
    //   width: 95%;
    //   border-radius: 100px;
    //   padding: 0 30px;
    // }

    div {
      // color: black;
    }
  }
  &__story {
    font-family: Pinyon, Georgia, "Times New Roman", Times, serif;
    font-size: 60px;
    line-height: 120%;
    color: $main-dark-color;
    @media screen and (max-width: 767px) {
      font-size: 42px;
    }
  }
  &__name {
    font-family: Roboto Mono, monospace;
    font-size: 18px;
    color: $main-dark-color;
  }
}

.cartolina {
  // width: 30vw;
  // height: auto;
  width: clamp(200px, 30vw, 500px);
  height: clamp(200px, 30vw, 500px);
  position: absolute;
  // top: -12vw;
  top: clamp(-250px, -12vw, -100px);
  right: clamp(50px, 10vw, 180px);
  z-index: -100;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
</style>
