<template>
  <div class="intro__container">
    <div class="cartolin__container">
      <div class="cartolin__header">
        <img src="../assets/LVDR_logo.svg" alt="" />
        <div class="cartolin__franc">
          <div class="border">
            <div class="loading"></div>
          </div>
        </div>
      </div>
      <div class="text__container">
        <div class="text__line">Ricordare…re-cordare</div>
        <div class="text__line desk">
          riportare al cuore,ridare vita a qualcosa
        </div>
        <div class="text__line desk">che non è più qui,non è più adesso.</div>
        <div class="text__line mob">riportare al cuore,</div>
        <div class="text__line mob">ridare vita a qualcosa</div>
        <div class="text__line mob">che non è più qui,</div>
        <div class="text__line mob">non è più adesso.</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    document.body.style.overflow = "hidden";
    document.body.style.height = "100%";
  },
};
</script>

<style lang="scss" scoped>
.intro__container {
  position: fixed;
  background: $main-color;
  width: 100%;
  height: 100vh;
  z-index: 999999;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cartolin__container {
  border: 1px solid $alt-background;
  width: clamp(300px, 90vw, 1120px);
  height: clamp(300px, 60vw, 700px);
  padding: 24px;
  @media screen and (max-width: 767px) {
    width: 90vw;
    height: 70vh;
  }
}

.cartolin__header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 32px;

  img {
    width: 15%;
    @media screen and (max-width: 767px) {
      width: 35%;
    }
  }
  .cartolin__franc {
    width: 8vw;
    height: 10vw;
    border: 2px dotted $alt-background;
    padding: 0.8vw;
    @media screen and (max-width: 767px) {
      padding: 6px;
      width: 60px;
      height: 75px;
    }
    .border {
      border: 1px solid $alt-background;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: flex-end;

      .loading {
        width: 100%;
        background: $alt-background;
        animation: load 4s ease-out 1 forwards;
        //   height: 100%;
      }
    }
  }
}

.text__container {
  margin: auto;
  width: 80%;
  margin-top: clamp(10px, 4vw, 20px);
  color: $main-dark-color;
  font-family: Pinyon, serif;
  text-align: center;

  div {
    width: 100%;
    margin-bottom: clamp(4px, 2vw, 10px);
    height: clamp(32px, 5vw, 70px);
    border-bottom: 2px dotted $alt-background;
    font-size: clamp(32px, 4vw, 60px);
  }
  @media screen and (min-width: 767px) {
    .mob {
      display: none;
    }
  }
  @media screen and (max-width: 767px) {
    width: 90%;
    margin-top: 7vh;

    div {
      font-size: clamp(18px, 7.5vw, 50px);
      height: clamp(24px, 9vw, 70px);
    }
    .desk {
      display: none;
    }
  }
}

@keyframes load {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}
</style>
