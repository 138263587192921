<template>
  <div class="home">
    <transition name="slide-fade" v-on:after-leave="afterLeave">
      <Intro v-if="show && first" />
    </transition>
    <NavBar />
    <search-bar v-if="!popup && search" />
    <popup-story v-if="popup" :storia="$store.state.storia" />
    <div class="list__container">
      <div class="list__header">
        <div class="list__headerLeft">
          Storie
          <div class="list__search">
            <img src="../assets/search.svg" alt="" @click="trigSearch" />
            <div
              class="search__word"
              v-if="$store.state.SearchStory"
              @click="$store.commit('updateSearch', '')"
            >
              {{ $store.state.SearchStory }}
              <img src="../assets/valo_close.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="list__headerLeft">Protagonisti</div>
      </div>
      <p class="errorMess" v-if="filte.length <= 0">
        Ops, la storia che cerchi non è qua...
      </p>
      <ListElement
        v-for="story in filte"
        :story="story"
        @mouseenter.native="hoverList()"
        @mouseleave.native="exitList()"
        :hover="hover"
        :key="story.titolo"
      />
    </div>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
import ListElement from "@/components/ListElement.vue";
import PopupStory from "@/components/popupStory.vue";
import Intro from "@/components/Intro.vue";
import SearchBar from "@/components/searchBar.vue";
export default {
  components: { NavBar, ListElement, PopupStory, Intro, SearchBar },
  data() {
    return {
      hover: false,
      response: null,
      show: true,
      filterList: null,
    };
  },
  methods: {
    async getContent() {
      // Query the API and assign the response to "response"
      const response = await this.$prismic.client.query(
        this.$prismic.Predicates.at("document.type", "ricordo"),
        {
          pageSize: 100,
        }
      );
      this.response = response.results;
      this.filterList = this.response;
    },
    hoverList() {
      this.hover = true;
    },
    exitList() {
      this.hover = false;
    },
    afterLeave() {
      document.body.style.overflow = "auto";
      this.$store.commit("firstLanding");
    },
    trigSearch() {
      this.$store.commit("toggleSearch");
      this.$store.commit("updateSearch", "");
    },
  },
  computed: {
    filte() {
      var newArr = [];
      if (this.response) {
        newArr = this.response.filter((fruit) => {
          return (
            fruit.data.titolo
              .toLowerCase()
              .includes(this.$store.state.SearchStory.toLowerCase()) ||
            fruit.data.nome
              .toLowerCase()
              .includes(this.$store.state.SearchStory.toLowerCase())
          );
          // return fruit.data.titolo.toLowerCase() === "la montagna";
        });
      }
      return newArr;
    },
    popup() {
      return this.$store.state.popup;
    },
    search() {
      return this.$store.state.searchBar;
    },
    first() {
      return this.$store.state.first;
    },
  },
  mounted() {
    this.show = false;
  },
  created() {
    // Call the API query method
    this.getContent();
  },
};
</script>

<style lang="scss" scoped>
.home {
  min-height: 100vh;
}
.list {
  &__container {
    margin: auto;
    margin-top: 116px;
    padding-bottom: 250px;
    width: clamp(300px, 90vw, 1120px);
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 8px;
    border-bottom: 2px black;
    border-bottom-style: dotted;
    margin-bottom: 20px;
    @media screen and (max-width: 767px) {
      align-items: flex-start;
      flex-direction: column;
    }

    div {
      font-family: Roboto, Arial, Helvetica, sans-serif;
      color: $main-color;
      font-size: 24px;
      font-weight: 500;
      @media screen and (max-width: 767px) {
        font-size: 18px;
      }
    }
  }

  &__headerLeft {
    display: flex;
    gap: 20px;
    align-items: center;

    @media screen and (max-width: 767px) {
      width: 100%;
      justify-content: space-between;
    }

    img {
      width: 24px;
      height: auto;
    }
  }
}

.slide-fade-enter-active {
  transition: all 3s ease;
}
.slide-fade-leave-active {
  transition: all 1.5s ease-in;
  // transition-delay: 60s;
  transition-delay: 6s;
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.list__search {
  display: flex;
  gap: 20px;
  img {
    cursor: pointer;
  }
  img:hover {
    filter: brightness(0.5);
  }
}

.search__word {
  cursor: pointer;
  display: flex;
  gap: 16px;
  color: $main-dark-color !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  border: 1px solid $main-dark-color;
  border-radius: 20px;
  &:hover {
    background: $main-color;
  }
  padding: 4px 16px;
  img {
    width: 14px;
    height: auto;
  }
}

.errorMess {
  font-family: Roboto Mono, monospace;
  color: $main-dark-color;
  font-size: 22px;
  margin-top: 42px;
}
</style>
