<template>
  <div class="footer__bg">
    <div class="footer__container">
      <div class="footer__top">
        <img src="../assets/LVDR_logo.svg" alt="" class="logo__footer" />
        <div class="partners__container">
          <h4>Un progetto in collaborazione con</h4>
          <div class="partners">
            <img src="../assets/marchi-partner.svg" alt="" />
            <!-- <img src="../assets/paloma-logo.svg" alt="" />
            <img src="../assets/ied-logo.svg" alt="" />
            <img src="../assets/delfini-logo.svg" alt="" />
            <img src="../assets/cattolica-logo.svg" alt="" />
            <img src="../assets/aux-logo copia.svg" alt="" /> -->
          </div>
        </div>
        <div class="sitemap">
          <router-link to="/">Esplora</router-link>
          <router-link to="/progetto">il Progetto</router-link>
          <router-link to="/contatti">Contatti</router-link>
          <a href="/">Press</a>
        </div>
      </div>
      <div class="footer__bottom">
        2022 La Valigia dei Ricordi - ALL RIGHTS RESERVED -
        <a href="/">Privacy Policy</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.footer__bg {
  width: 100%;
  padding: 45px 0;
  background: $alt-background;
}

.footer__container {
  max-width: 900px;
  padding: 0 20px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  margin-bottom: 16px;
  border-bottom: 2px dotted $main-dark-color;
  @media screen and (max-width: 767px) {
    flex-direction: column;
    gap: 28px;
  }
}

.logo__footer {
  width: 114px;
  height: autpo;
}

.partners__container {
  width: 60%;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  h4 {
    font-family: Roboto Mono, monospace;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
  }

  .partners {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    img {
      // width: 20%;
    }
  }
}

.sitemap {
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }

  a {
    color: $main-dark-color;
    text-decoration: none;
    text-align: right;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    margin-bottom: 6px;

    &:hover {
      text-decoration: underline;
    }
  }

  .router-link-exact-active {
    color: $main-color;
  }
}

.footer__bottom {
  color: $main-dark-color;
  font-family: Roboto Mono, monospace;
  font-size: 12px;
  @media screen and (max-width: 767px) {
    text-align: center;
  }
  a {
    text-decoration: none;
    color: $main-dark-color;

    &:hover {
      text-decoration: underline;
    }
  }
}
</style>
