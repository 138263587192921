import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import HomeNew from "../views/HomeNew.vue";
import Contatti from "../views/Contatti.vue";
import RedirectPage from "../views/RedirectPage.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/test",
    name: "home",
    component: HomeView,
  },
  {
    path: "/",
    name: "homeNew",
    component: HomeNew,
  },
  {
    path: "/contatti",
    name: "contatti",
    component: Contatti,
  },
  {
    path: "/progetto",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/:id",
    name: "redirect-page",
    component: RedirectPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

export default router;
