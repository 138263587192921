<template>
  <div id="app">
    <router-view />
    <FooterComp
      v-if="$route.name !== 'home' && $route.name !== 'redirect-page'"
    />
  </div>
</template>

<script>
import FooterComp from "./components/FooterComp.vue";
export default { components: { FooterComp } };
</script>

<style lang="scss">
@font-face {
  font-family: "Droid Sans Mono";
  src: url("./assets/fonts/DroidSansMono.woff2") format("woff2"),
    url("./assets/fonts/DroidSansMono.woff") format("woff"),
    url("./assets/fonts/DroidSansMono.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Droid Sans";
  src: url("./assets/fonts/DroidSans.woff2") format("woff2"),
    url("./assets/fonts/DroidSans.woff") format("woff"),
    url("./assets/fonts/DroidSans.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Droid Sans";
  src: url("./assets/fonts/DroidSans-Bold.woff2") format("woff2"),
    url("./assets/fonts/DroidSans-Bold.woff") format("woff"),
    url("./assets/fonts/DroidSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Pinyon";
  src: url("./assets/fonts/PinyonScript.woff2") format("woff2"),
    url("./assets/fonts/PinyonScript.woff") format("woff"),
    url("./assets/fonts/PinyonScript.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

#app {
  font-family: Roboto, Helvetica, Arial, sans-serif;
  color: $main-dark-color;
}

::-moz-selection { /* Code for Firefox */
  color: white;
  background: $main-dark-color;
}

::selection {
  color: white;
  background: $main-dark-color;
}
</style>
