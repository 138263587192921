import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import PrismicVue from "@prismicio/vue";
import linkResolver from "./link-resolver";
import VueYoutube from "vue-youtube";

const accessToken = ""; // Leave empty if your repo is public
const endpoint = "https://lvdr.prismic.io/api/v2"; // Use your repo name

Vue.config.productionTip = false;

Vue.use(VueYoutube);
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
