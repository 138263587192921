// The Link Resolver takes a Prismic document as its argument
export default function (doc) {
  // Then it executes a switch to determine how to treat the item
  if (doc.type === "women_profile") {
    // For a 'post', you could use the post's Unique ID (UID)
    return "/view-all/" + doc.uid;
  }
  if (doc.isBroken) {
    return "/not-found";
  }
  // For other document types, just use the UID
  // Otherwise, return a 404
  return "/not-found";
}
