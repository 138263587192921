<template>
  <div class="navbar__container">
    <router-link to="/"
      ><img src="../assets/lvdr-logo-color.svg" alt="" class="navbar__logo"
    /></router-link>
    <div class="navbar__linkContainer">
      <router-link class="navbar__link" to="/">Esplora</router-link>
      <router-link class="navbar__link" to="/progetto">il Progetto</router-link>
      <router-link class="navbar__link" to="/contatti">Contatti</router-link>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.navbar {
  &__container {
    display: flex;
    margin-top: 40px;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 767px) {
      // display: none;
      flex-direction: column;
      margin-top: 20px;
    }
  }
  &__logo {
    width: 180px;
    height: auto;
    @media screen and (max-width: 767px) {
      width: 110px;
    }
  }
  &__linkContainer {
    margin-left: 100px;
    display: flex;
    gap: 42px;
    @media screen and (max-width: 767px) {
      margin: auto;
      margin-top: 20px;
    }
  }
  &__link {
    font-family: Roboto, Arial, Helvetica, sans-serif;
    font-weight: bold;
    font-size: 20px;
    color: $main-color;
    text-decoration: none;

    &:hover {
      color: black;
    }
  }
}
.router-link-exact-active {
  color: black;
}
</style>
