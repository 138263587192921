<template>
  <div class="popup__bg" @click="close">
    <div class="close__button--mob" @click="$store.commit('closePopup')">
      <img src="../assets/valo_close.svg" alt="" />
    </div>
    <div class="popup__container">
      <div class="close__button--desk" @click="$store.commit('closePopup')">
        <img src="../assets/valo_close.svg" alt="" />
      </div>
      <div class="video__container" id="video">
        <youtube
          class="video_yt"
          :video-id="getId(storia.video.url)"
          :player-vars="playerVars"
        ></youtube>
      </div>
      <div class="text__container" id="text">
        <h3>{{ storia.nome }}</h3>
        <h2>{{ storia.titolo }}</h2>
        <p>{{ storia.storia[0].text }}</p>
      </div>
      <div class="img__container" id="image">
        <div
          class="img__cartolina"
          :style="`background-image: url(${storia.cartolina.url})`"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    storia: Object,
  },
  data() {
    return {
      videoId: "c_ArwWbYPSM",
      playerVars: {
        autoplay: 0,
      },
    };
  },
  methods: {
    getId(x) {
      return this.$youtube.getIdFromUrl(x);
    },
    close(e) {
      if (e.target.className === "popup__bg") {
        this.$store.commit("closePopup");
      }
    },
  },
};
</script>

<style lang="scss">
.popup {
  &__bg {
    position: fixed;
    overflow-y: scroll;
    overscroll-behavior: contain;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9999999;
    background-color: rgba(55, 55, 55, 0.5);
    @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
      -webkit-backdrop-filter: blur(5px);
      backdrop-filter: blur(5px);
      background-color: rgba(55, 55, 55, 0.5);
    }
  }

  &__container {
    position: relative;
    width: clamp(300px, 70vw, 1008px);

    // height: 80vh;
    background: white;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: 1fr 1fr;

    @media screen and (max-width: 767px) {
      width: 85vw;
      height: auto;
      grid-template-columns: 1;
      grid-template-rows: repeat(3, auto);
    }
  }
}

.video_yt {
  height: 42vh;
  max-height: 380px;
  width: 100%;
  @media screen and (max-width: 767px) {
    height: 25vh;
    width: 100%;
  }
}

#video {
  grid-row: 1;
  grid-column: 1 / end;
  background: white;
  display: flex;
  justify-content: center;
  padding: 16px;
  @media screen and (max-width: 767px) {
    padding: 0;
    //   height: 35vw;
  }
}
#text {
  grid-row: 2;
  grid-column: 1 / 4;
  background: white;
  padding: 20px;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    grid-column: 1 / end;
    padding: 22px 20px 8px 20px;
  }
  //   justify-content: space-between;

  h3 {
    font-family: Roboto Mono, monospace;
    color: $main-dark-color;
    font-size: 20px;
    font-weight: normal;
    margin-bottom: 18px;
    @media screen and (max-width: 767px) {
      font-size: 15px;
    }
  }

  h2 {
    font-family: Pinyon, serif;
    color: $main-dark-color;
    font-size: clamp(36px, 4vw, 60px);
    font-weight: normal;
    margin-bottom: 18px;
    line-height: 110%;
    @media screen and (max-width: 767px) {
      margin-bottom: 18px;
    }
  }

  p {
    font-family: Roboto Mono, monospace;
    font-size: 15px;
    color: $main-dark-color;
  }
}
#image {
  grid-row: 2;
  grid-column: 4 / end;
  padding: 20px;
  padding-right: 50px;
  @media screen and (max-width: 767px) {
    min-height: 25vh;
    padding: 8px;
    padding-top: 20px;
    grid-column: 1 / end;
    grid-row: 3;
  }
}

.img__cartolina {
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.player {
  border: 1px solid red;
}

.close__button {
  &--desk {
    cursor: pointer;
    position: absolute;
    padding: 10px;
    border-radius: 20px;
    top: 16px;
    right: 16px;
    &:hover {
      background: $alt-background;
    }
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  &--mob {
    border-radius: 20px;
    padding: 10px;
    position: fixed;
    top: 20px;
    right: 20px;
    background: $alt-background;
    z-index: 9999999;
    @media screen and (min-width: 767px) {
      display: none;
    }
  }
}
</style>
