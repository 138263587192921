<template>
  <div class="contatti">
    <NavBar />
    <h1>Scrivi a Paloma 2000</h1>
    <form
      class="form__container"
      name="contact"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <div class="francobollo">
        <div></div>
      </div>
      <input type="hidden" name="form-name" value="contact" />
      <input
        type="text"
        name="nome"
        v-model="FormData.nome"
        required
        placeholder="Il tuo nome"
      />
      <input
        type="email"
        name="email"
        v-model="FormData.email"
        required
        placeholder="La tua email"
      />
      <input
        type="text"
        name="oggetto"
        v-model="FormData.oggetto"
        required
        placeholder="Oggetto"
      />
      <textarea
        name="messaggio"
        v-model="FormData.messaggio"
        required
        placeholder="Il tuo messaggio"
        rows="10"
      ></textarea>
      <button type="submit">Invia <span>&#8594;</span></button>
    </form>
  </div>
</template>

<script>
import NavBar from "@/components/NavBar.vue";
export default {
  components: { NavBar },
  data() {
    return {
      FormData: {
        nome: "",
        oggetto: "",
        email: "",
        messaggio: "",
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.contatti {
  width: 100%;
  padding: 0 10px;

  h1 {
    margin-top: 90px;
    margin-bottom: 90px;
    font-family: Pinyon, serif;
    color: $main-dark-color;
    font-weight: normal;
    font-size: 60px;
    text-align: center;
    @media screen and (max-width: 767px) {
      font-size: 42px;
    }
  }
}

.form__container {
  position: relative;
  margin: auto;
  width: clamp(300px, 90vw, 1000px);
  max-width: 1000px;
  border: 1px solid $main-dark-color;
  padding: 62px;
  margin-bottom: 160px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  font-family: Roboto Mono, monospace;
  color: $main-dark-color;
  @media screen and (max-width: 767px) {
    padding: 32px 20px;
  }

  input {
    border: none;
    border-bottom: 2px dotted $main-dark-color;
    width: 50%;
    @media screen and (max-width: 767px) {
      width: 100%;
    }

    &:focus {
      outline-color: $main-dark-color;
    }
  }

  textarea {
    resize: none;
    border: 2px dotted $main-dark-color;
    padding: 16px;
    margin-top: 32px;
  }

  button {
    border: none;
    outline: none;

    align-self: flex-end;
    background: none;
    cursor: pointer;

    &:hover {
      background: $main-color;
    }
  }
}

.francobollo {
  @media screen and (max-width: 767px) {
    display: none;
  }
  position: absolute;
  width: 110px;
  height: 140px;
  border: 2px dotted $main-dark-color;
  top: 42px;
  right: 62px;
  padding: 10px;
  div {
    border: 1px solid $main-dark-color;
    width: 100%;
    height: 100%;
  }
}
</style>
