import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    popup: false,
    storia: {},
    first: true,
    searchBar: false,
    SearchStory: '',
  },
  getters: {},
  mutations: {
    toggleSearch(state) {
      state.searchBar = !state.searchBar;
    },
    openPopup(state, story) {
      state.popup = true;
      state.storia = story;
    },
    firstLanding(state) {
      state.first = false;
    },
    closePopup(state) {
      state.popup = false;
    },
    updateSearch (state, input) {
      state.SearchStory = input;
    }
  },
  actions: {},
  modules: {},
});