<template>
  <div class="home">
    <div class="logo">
      <img src="../assets/LVDR_logo.svg" alt="" />
    </div>
    <div class="content">
      <div class="hero_img">
        <img src="../assets/valigia.png" alt="" />
      </div>
      <div class="text">
        <h1>STIAMO AGGIORNANDO IL SITO</h1>
        <h2>Torna a trovarci a inizio Giugno</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeView",
};
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  min-height: 100vh;
  background: $light-background;
}

.logo {
  margin: auto;
  padding-top: 50px;
  width: 200px;
}

.content {
  margin: auto;
  margin-top: 40px;
  @media screen and (max-width: 768px) {
    margin-top: 13vh;
  }

  img {
    width: 500px;
    margin: auto;
    @media screen and (max-width: 768px) {
      width: 300px;
    }
  }

  .text {
    margin: 0 20px;
    margin-top: 80px;
    text-align: center;

    h1 {
      font-family: Droid Sans, Arial, Helvetica, sans-serif;
      font-weight: bold;
      font-size: 26px;
    }
    h2 {
      margin-top: 20px;
      font-family: Droid Sans Mono, Arial, Helvetica, sans-serif;
      font-weight: normal;
      font-size: 26px;
    }
    @media screen and (max-width: 768px) {
      margin-top: 13vh;
      h1 {
        font-size: 18px;
      }
      h2 {
        font-size: 18px;
      }
    }
  }
}
</style>
