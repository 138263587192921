<template>
  <div></div>
</template>

<script>
export default {
  created() {
    if (this.$route.params.id === "anna") {
      if (this.$route.hash === "#lafamiglia") {
        window.location.replace("https://youtu.be/nq1AN-LhmB8");
      } else if (this.$route.hash === "#sempreinsieme") {
        window.location.replace("https://www.youtube.com/watch?v=5FPxGUjeGPQ");
      }
    } else if (this.$route.params.id === "francesco") {
      if (this.$route.hash === "#fotodifamiglia") {
        window.location.replace("https://youtu.be/zG1FqI_JoCo");
      } else if (this.$route.hash === "#mobilebar") {
        window.location.replace("https://youtu.be/xiL4gAkLgUo");
      } else if (this.$route.hash === "#ilmatrimonio") {
        window.location.replace("https://youtu.be/N94B1i6MKF0");
      } else if (this.$route.hash === "#conglianimali") {
        window.location.replace("https://youtu.be/tA1AbP3syOs");
      } else if (this.$route.hash === "#ilmiopapa") {
        window.location.replace("https://youtu.be/mnxUprAa4vY");
      } else if (this.$route.hash === "#lamalattia") {
        window.location.replace("https://youtu.be/YwW22s1XqfU");
      }
    } else if (this.$route.params.id === "germana") {
      if (this.$route.hash === "#leorigini") {
        window.location.replace("https://youtu.be/DD6aK9kLX-8");
      } else if (this.$route.hash === "#ilmatrimonio") {
        window.location.replace("https://youtu.be/BTSyeP0Kv7Q");
      } else if (this.$route.hash === "#ilbar") {
        window.location.replace("https://youtu.be/BCw92LABuaU");
      } else if (this.$route.hash === "#lacampagna") {
        window.location.replace("https://youtu.be/tR17wpMXqgw");
      }
    } else if (this.$route.params.id === "leontina") {
      if (this.$route.hash === "#abitobianco") {
        window.location.replace("https://youtu.be/LjIhhfDBl7w");
      } else if (this.$route.hash === "#mammapremurosa") {
        window.location.replace("https://youtu.be/Ajz7S-dxPk4");
      } else if (this.$route.hash === "#teresina") {
        window.location.replace("https://youtu.be/WfnnoU2BY-I");
      } else if (this.$route.hash === "#lamalattia") {
        window.location.replace("https://youtu.be/kC31hn0IVg0");
      }
    }
  },
};
</script>

<style lang="scss" scoped></style>
